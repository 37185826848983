<template>
  <remote-search
    :id="id"
    :model-value="modelValue"
    :remote-method="searchResource"
    :result-filter="resultFilter"
    :list="resourceList"
    :option-value="optionsValue"
    :placeholder="
      placeholder || $t('components.remote_search_select.placeholder_default')
    "
    :loading="isLoading || loading"
    :error-text="$t('common.errors.fetch.resource.message', { resource })"
    :validate-input="validateInput()"
    :disabled="disabled"
    :clearable="clearable"
    :invalid-search-text="invalidSearchText"
    :popper-append-to-body="popperAppendToBody"
    @update:change="handleInput"
    @paginate="paginate"
    @computed-list="ensurePaginationForFilteredList"
    @clear="$emit('clear')"
  >
    <template v-if="$slots.append" #append>
      <slot name="append" />
    </template>
  </remote-search>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import typeOf from 'just-typeof'
import get from 'just-safe-get'
import pick from 'just-pick'
import RemoteSearch from './remote-search'
import { isNullish } from '@/utils/general'
import compose from 'just-compose'
import {
  dedupeByKey,
  getDataFromResponse,
  toArray,
  unwrapData,
  dedupeData
} from './helpers'

export default {
  components: {
    RemoteSearch
  },
  props: {
    modelValue: {
      default: null,
      validator: (prop) => typeof prop === 'string' || prop === null
    },
    resource: {
      type: String,
      required: true,
      validator: (resource) => {
        const allowedResources = [
          'products',
          'customers',
          'customersV1',
          'users',
          'staff',
          'branches',
          'branchesV1',
          'branchGroups',
          'productGroups',
          'registers',
          'reasons',
          'contents',
          'contentTemplates',
          'favourites',
          'contents',
          'accounts',
          'taxes',
          'discounts',
          'expenseAccounts'
        ]
        return allowedResources.includes(resource)
      }
    },
    // In case of the Users resource, it needs a configurationId to initialize.
    resourceId: {
      type: String,
      default: undefined
    },
    modifyQuery: {
      type: Function,
      default: undefined
    },
    computedFields: {
      type: Array,
      default: () => ['name']
    },
    placeholder: {
      type: String,
      default: ''
    },
    resultFilter: {
      type: Function,
      default: (results) => results
    },
    computeName: {
      type: Function,
      default: undefined
    },
    computeDescription: {
      type: Function,
      default: undefined
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    doInitialFetch: {
      type: Boolean,
      default: true
    },
    // Case of user, there is no search handler, so we use a custom
    fetchHandler: {
      type: String,
      default: undefined
    },
    overrideInitialFetchHandler: {
      type: String,
      default: undefined
    },
    minSearchTextLength: {
      type: Number,
      default: undefined
    },
    /**
     * The key used to get the value for the options-children of the dropdown.
     * Defaults to "id"
     */
    optionsValue: {
      type: String,
      default: 'id'
    },
    /**
     * In some use cases we care for just a specific value in the returned search api array of matched result objects, and don't care about the other part of the result. In that case we don't want to have duplicate values presented to the user. "noRepeatKey" prop should provide a path to the specific value in the result object.
     *
     * E.g. we want to get all the cities that starts with "ber" that branches are located in. The search-api will return the entire branches' objects for this result, so we might get a lot of results with "Berlin" as the city key value. In order to not show the user a dropdown with repeated "Berlin" values, we will pass "noRepeatKey" with the 'city' as its value (or whatever is the relevant specific key).
     */
    noRepeatKey: {
      type: String,
      default: undefined
    },
    clearable: {
      type: Boolean,
      default: true
    },
    id: {
      type: String,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    },
    popperAppendToBody: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'initial-resource-set',
    'update:modelValue',
    'resource-set',
    'clear',
    'loading-error'
  ],
  data() {
    return {
      resourceList: [],
      next: null,
      isLoading: false
    }
  },
  computed: {
    invalidSearchText() {
      if (!Number.isFinite(Number(this.minSearchTextLength))) return

      return this.$t('pages.customers.edit.form.field_warnings.min_length', {
        length: this.minSearchTextLength
      })
    }
  },
  watch: {
    modelValue() {
      const matchedValue = this.resourceList.find(
        (resource) =>
          String(resource[this.optionsValue || 'id']) ===
          String(this.modelValue)
      )
      if (!matchedValue) this.fetchInitialValue()
      else this.$emit('resource-set', matchedValue)
    }
  },
  async mounted() {
    this.resourceList = this.computeOptions(this.options)
    await this.fetchInitialValue()
    this.fetchFullList()
  },
  methods: {
    async fetchInitialValue() {
      if (!this.modelValue || !this.doInitialFetch) return

      const handlerName =
        this.overrideInitialFetchHandler ||
        this.fetchHandler ||
        (this.optionsValue === 'id' ? 'get' : 'getAll')

      const [err, item] = await this.fetch({
        handlerName,
        query: this.modelValue
      })
      if (err) return
      this.$emit('initial-resource-set', Array.isArray(item) ? item[0] : item)
      this.addItemsToList(item)
    },
    async fetchFullList() {
      const [err, data] = await this.fetch({
        handlerName: this.fetchHandler || 'getAll'
      })
      if (err) return
      this.addItemsToList(data)
    },
    async searchResource(searchTerm) {
      if (!searchTerm) return

      this.isLoading = true
      const [err, data] = await this.fetch({
        handlerName: this.fetchHandler || 'search',
        query: searchTerm
      })
      this.isLoading = false
      const errorCode = get(err, 'properties.error.response.status')
      return [errorCode, data]
    },
    async fetch({ handlerName, query }) {
      if (!th[this.resource]) {
        const error = new Error(
          `${this.resource} is not an instantiable resource`
        )
        this.$logException(error)
        return [error]
      }

      const inst = this.resourceId
        ? th[this.resource](this.resourceId)
        : th[this.resource]()

      if (typeOf(inst[handlerName]) !== 'function') {
        const error = new Error(
          `${handlerName} for resource ${this.resource} is not a function`
        )
        this.$logException(error)
        return [error]
      }

      this.isLoading = true

      // Get query params
      const q =
        this.modifyQuery && handlerName !== 'get'
          ? this.modifyQuery(query, handlerName)
          : query

      let response
      try {
        // Make the request
        response = await inst[handlerName](q)
      } catch (err) {
        this.isLoading = false
        this.$emit('loading-error', err)
        this.$logException(err, { trackError: false })
        return [err]
      }

      this.isLoading = false

      // Parse data
      const data = compose(
        getDataFromResponse,
        toArray,
        unwrapData,
        dedupeData(this.noRepeatKey),
        (data) => this.computeOptions(data, query)
      )(response)

      if (response.next) {
        this.next = response.next
      }

      if (
        this.modelValue &&
        ['search', 'get', 'getAll'].includes(handlerName)
      ) {
        // Update parent with the item
        const item = data.find(
          (resource) =>
            String(resource[this.optionsValue]) === String(this.modelValue)
        )
        item && this.$emit('resource-set', item)
      }
      return [null, data]
    },
    handleInput(item) {
      if (!item) {
        this.$emit('update:modelValue', undefined)
        this.$emit('resource-set', undefined)
        return
      }

      if (this.handleChange) this.handleChange()
      this.addItemsToList([item])
      this.$emit('update:modelValue', item[this.optionsValue])
      this.$emit('resource-set', item)
    },
    computeOptions(data, query) {
      return data
        .map((item) => {
          if (!item || typeOf(item) !== 'object') return

          // NOTE: Persist original user input as "queryInput".
          // E.g. to optionally use with a provided expandOriginalData function in the filter object which would be passed to the parent component.
          const obj = { ...item, queryInput: query }

          obj.computed_name = this.computeName
            ? this.computeName(item, query)
            : this.defaultCompute(item)

          obj.computed_description = this.computeDescription
            ? this.computeDescription(item)
            : null

          return obj
        })
        .filter(Boolean)
    },
    defaultCompute(item) {
      return Object.entries(pick(item, this.computedFields))
        .filter(([, item]) => !isNullish(item))
        .map(([, item]) => item)
        .join(' - ')
        .trim()
    },
    async paginate() {
      if (!this.next) return
      try {
        this.isLoading = true
        const { data = [], next = null } = await this.next()

        this.addItemsToList(data)

        this.next = next
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.isLoading = false
      }
    },
    validateInput() {
      const fn = (input) => this.minSearchTextLength <= input.length
      if (Number.isFinite(Number(this.minSearchTextLength))) return fn
      return undefined
    },
    addItemsToList(items) {
      this.resourceList = compose(
        this.computeOptions,
        (opts) => this.resourceList.concat(opts),
        dedupeByKey
      )(items)
    },
    ensurePaginationForFilteredList(items) {
      if (items.length < 15 && this.next) {
        this.paginate()
      }
    }
  }
}
</script>
