<template>
  <div class="relative" :class="{ 'has-append': $slots.append }">
    <button
      type="button"
      class="w-full focus:outline-none"
      tabindex="-1"
      @click.stop.prevent="toggleList(!listOpen)"
    >
      <el-input
        :id="id"
        ref="searchInput"
        v-model="searchValue"
        v-loading="!listOpen && loading"
        class="th-input"
        :class="{ disabled }"
        :clearable="clearable"
        :placeholder="placeholder"
        :disabled="disabled"
        @update:modelValue="handleInput"
        @focus="handleInputFocus"
        @blur="handleInputBlur"
        @clear="handleClear"
      />
    </button>

    <div v-if="$slots.append" class="append-slot">
      <slot name="append" />
    </div>

    <transition :name="transition">
      <div
        v-show="listOpen"
        ref="list"
        class="el-select-dropdown el-popper absolute border rounded-th-normal bg-white my-3"
        :x-placement="popper.placement"
        :style="{ width: `${width}px`, zIndex: 3000 }"
      >
        <div
          v-if="computedList.length"
          class="overflow-hidden"
          :class="[computedList.length > 8 ? 'th-list-height' : 'h-full']"
        >
          <virtual-list
            ref="dropdown"
            :model-value="modelValue"
            :loading="loading"
            :label-field="optionValue || labelField"
            :list="computedList"
            @select="pickItem($event)"
            @paginate="!searchTerm && $emit('paginate')"
          />
        </div>
        <div
          v-else
          class="h-full flex justify-center items-center leading-8 text-th-primary-gray"
          style="min-height: 4rem"
        >
          <div v-if="showSearchWarning">{{ _invalidSearchText }}</div>
          <div v-else-if="error">{{ _errorText }}</div>
          <div v-else-if="loading">{{ _loadingText }}</div>
          <div v-else>{{ _noDataText }}</div>
        </div>
        <div class="popper__arrow" style="left: 35px" />
      </div>
    </transition>
  </div>
</template>

<script>
import debounce from 'debounce'
import popperComponent from '../popper'
import VirtualList from './virtual-list'

export default {
  name: 'RemoteSearch',
  components: {
    VirtualList
  },
  props: {
    modelValue: {
      type: String,
      default: undefined
    },
    optionValue: {
      type: String,
      default: undefined
    },
    id: {
      type: String,
      default: ''
    },
    remoteMethod: {
      type: Function,
      default: async () => {}
    },
    resultFilter: {
      type: Function,
      default: (results) => results
    },
    placeholder: {
      type: String,
      default: ''
    },
    loadingText: {
      type: String,
      default: undefined
    },
    noDataText: {
      type: String,
      default: undefined
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: String,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    },
    labelField: {
      type: String,
      default: 'name'
    },
    validateInput: {
      type: Function,
      default: (input) => input.length >= 3
    },
    invalidSearchText: {
      type: String,
      default: undefined
    },
    popperAppendToBody: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      width: 260, // width matches the input in the datatable filter - which seems to not be able to be calculated via `getWidth`
      listOpen: false,
      error: false,
      searchTerm: '',
      remoteList: [],
      popper: {}
    }
  },
  computed: {
    searchValue: {
      get() {
        return this.searchTerm || this.displayValue
      },
      set(value) {
        this.searchTerm = value
      }
    },
    displayValue() {
      if (!this.modelValue) return ''
      const item = this.list.find(
        (item) => (item[this.optionValue] || item.id) === this.modelValue
      )
      return item
        ? item.computed_name || item[this.optionValue] || item[this.labelField]
        : ''
    },
    showSearchWarning() {
      return this.searchTerm && !this.isSearchTermValid
    },
    computedList() {
      if (this.showSearchWarning) return []
      if (this.searchTerm) return this.resultFilter(this.remoteList)
      return this.resultFilter(this.list)
    },
    isSearchTermValid() {
      return this.validateInput(this.searchTerm)
    },
    _invalidSearchText() {
      return (
        this.invalidSearchText ||
        this.$t('pages.customers.edit.form.field_warnings.min_length', {
          length: 3
        })
      )
    },
    _noDataText() {
      return this.noDataText || this.$t('common.data.no_data')
    },
    _errorText() {
      return this.errorText || this.$t('pages.home.widgets.timeout_error')
    },
    _loadingText() {
      return this.loadingText || this.$t('common.interactions.loading.default')
    },
    transition() {
      const popperPlacementY = this.popper?.placement?.split('-')[0] ?? 'bottom'
      return {
        top: 'el-zoom-in-bottom',
        bottom: 'el-zoom-in-top'
      }[popperPlacementY]
    }
  },
  watch: {
    disabled(newValue) {
      // close list if disabled changes
      if (newValue) this.listOpen = false
    },
    searchTerm(newValue, oldValue) {
      // remove value when finishing to backspace the entire input
      if (newValue === '' && oldValue.length) {
        this.$emit('update:modelValue', '')
      }
    },
    computedList(newValue) {
      this.$emit('computed-list', newValue)
    }
  },
  mounted() {
    window.addEventListener('resize', this.getWidth)
    this.getWidth()
    this.popper = popperComponent.setup(
      this.$refs.searchInput.$el,
      this.$refs.list,
      {
        placement: 'bottom-start'
      },
      this.popperAppendToBody
    )
    this.popper.create()
  },
  beforeUnmount() {
    // clear the passed list so it won't persist for next mount.
    this.remoteList = []
    this.error = false
    window.removeEventListener('resize', this.getWidth)
    this.popper.destroy()
  },
  methods: {
    getWidth() {
      const inputWidth = this.$refs.searchInput?.$el.getBoundingClientRect()
        .width
      if (inputWidth > 0) {
        this.width = inputWidth
      }
    },
    triggerRemoteMethod: debounce(async function (value) {
      this.error = false
      const res = await this.remoteMethod(value)
      const [error = null, list = []] = res || []
      this.remoteList = list
      this.error = error && Number(error) >= 400
      if (!this.listOpen) this.toggleList(true)
    }, 200),
    handleInput(value) {
      if (!value) {
        this.searchTerm = ''
        return this.$emit('update:change', undefined)
      }
      this.searchTerm = value
      if (!this.isSearchTermValid) {
        this.remoteList = []
        return
      }
      this.triggerRemoteMethod(this.searchTerm)
    },
    toggleList: debounce(async function (openState) {
      if (this.disabled) return
      await this.$nextTick()
      this.listOpen = openState
      await this.$nextTick()
      this.popper.computePlacement()
    }, 150),
    handleInputFocus() {
      this.toggleList(true)
    },
    handleInputBlur() {
      this.toggleList(false)
    },
    pickItem(item) {
      this.searchTerm = ''
      this.remoteList = []
      this.toggleList(false)
      this.$nextTick(() => {
        this.$emit('update:change', item)
      })
    },
    handleClear() {
      this.$emit('clear')
      // In case of clicking the clear button from *outside* of the input, we want to the input to be focused.
      this.$refs.searchInput.focus()
    }
  }
}
</script>

<style scoped>
#th-popper {
  min-width: 300px;
}

.th-input :deep(.el-input__inner) {
  cursor: pointer;
}

.th-input.disabled :deep(.el-input__inner) {
  cursor: not-allowed;
}

.th-input :deep(.el-loading-mask) {
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  border-radius: 3px;
}

.th-input :deep(.el-loading-spinner) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  top: 0;
  margin-top: 0;
}

.th-input :deep(.el-loading-spinner svg) {
  height: 25px;
  width: 25px;
}

.th-list-height {
  height: 20rem;
}

.has-append {
  display: flex;
}

.has-append :deep(.el-input__inner) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0 none;
}

.has-append .append-slot button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  align-items: center;
}
</style>
