import get from 'just-safe-get'

/**
 * De-duplicates an array of objects by a given key.
 * @param {array} data - array of objects
 * @param {string} key - key to deduplicate the data list based on
 * @returns {array} - array of objects
 */
export const dedupeByKey = (data, key = 'id') => {
  const seen = new Set()
  return data.filter((item) => {
    const k = get(item, key)
    return seen.has(k) ? false : (seen.add(k), true)
  })
}

// Search API returns data inside of "starts_with" or "search" property - depends on the version of the handler.
export const getDataFromResponse = (res) =>
  get(res, 'data.starts_with') || get(res, 'data.search') || res.data

// In case of the response being one object, turn it into an array
export const toArray = (data) => (Array.isArray(data) ? data : [data])

// Search API version that returns the data under the "search" property also wraps each data item inside of a "doc" property.
export const unwrapData = (data) => data.map((item) => get(item, 'doc', item))

export const dedupeData = (dedupeKey) => (data) =>
  dedupeKey ? dedupeByKey(data, dedupeKey) : data
